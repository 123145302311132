import { useEffect, useState } from 'react';

interface IMobileDetect {
  isAndroid?: boolean;
  isIos?: boolean;
  isOpera?: boolean;
  isWindows?: boolean;
  isSSR?: boolean;
  isMobile?: boolean;
  isDesktop?: boolean;
}

const getMobileDetect = (
  userAgent: NavigatorID['userAgent']
): IMobileDetect => {
  const isAndroid = Boolean(userAgent.match(/Android/i));
  const isIos = Boolean(userAgent.match(/iPhone|iPod/i));
  const isOpera = Boolean(userAgent.match(/Opera Mini/i));
  const isWindows = Boolean(userAgent.match(/IEMobile/i));
  const isSSR = Boolean(userAgent.match(/SSR/i));
  const isMobile = isAndroid || isIos || isOpera || isWindows;
  const isDesktop = !isMobile && !isSSR;
  return {
    isMobile,
    isDesktop,
    isAndroid,
    isIos,
    isSSR,
  };
};
const useMobileDetect = (): IMobileDetect => {
  const [userAgent, setUserAgent] = useState({});
  useEffect(() => {
    const userAgent =
      typeof navigator === 'undefined' ? 'SSR' : navigator.userAgent;
    setUserAgent(getMobileDetect(userAgent));
  }, []);

  return userAgent;
};

export default useMobileDetect;
