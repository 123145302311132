import React, { Fragment, useEffect, useState } from 'react';
import Head from 'next/head';
import { Carousel } from 'react-responsive-carousel';
import { Image } from 'components/Image';
import { ConvertStringToCtaType } from 'components/Buttons';
import { Advantage } from 'components/Advantage';
import { ExtraNews } from 'components/ExtraNews';
import { BGShapes } from 'components/BGShapes';
import { CTABanner } from 'components/CTABanner';
import { Teaser } from 'components/Teaser/Teaser';
import { TeamBlock } from 'components/TeamBlock';
import { SectionHeader } from 'components/Text';
import { Reference } from 'components/Reference';
import { RedactorContent } from 'components/redactor-content';
import { Project } from 'components/Project';
import { Hero } from 'components/Hero';
import { useWindowSize } from 'hooks';
import { useInView } from 'react-intersection-observer';
import { lozadObserver } from 'pages/_app';
import {
  getAllBlogsData,
  getCommonData,
  getCookieBannerData,
  getHomePageData,
  getNewsLetterData,
  getServiceLinksData,
} from 'queries';
import { GetStaticProps } from 'next';
import dynamic from 'next/dynamic';
import { useRouter } from 'next/router';
import useMobileDetect from '../hooks/mobileDetect';

const BlogList = dynamic(() => import('components/BlogList'));
const Header = dynamic(() => import('components/Header/Header'));
const Footer = dynamic(() => import('components/Footer'));
const NewsLetter = dynamic(() => import('components/NewsLetter'));

// const language = (typeof window !== "undefined") ?
//   window.navigator.language === 'nl-BE' ? 1 : 1
//   : '1';

export const getStaticProps: GetStaticProps = async (context) => {
  const [
    homePageData,
    serviceLinksData,
    commonData,
    newsLetterData,
    cookieBannerData,
    blogsData,
  ] = await Promise.all([
    getHomePageData(context),
    getServiceLinksData(context),
    getCommonData(context),
    getNewsLetterData(context),
    getCookieBannerData(context),
    getAllBlogsData(context, { limit: 3 }),
  ]);

  return {
    revalidate: 60,
    props: {
      homePageData,
      commonData,
      serviceLinksData,
      newsLetterData,
      cookieBannerData,
      blogsData,
    },
  };
};

export const Index = ({
  homePageData,
  serviceLinksData,
  commonData,
  newsLetterData,
  cookieBannerData,
  blogsData,
}) => {
  const router = useRouter();
  const [lastScrollY, setLastScrollY] = useState(0);
  const [points, setPoints] = useState('');
  const { width, height } = useWindowSize();
  const [ref, inView] = useInView({ threshold: 0.2 });
  const mobileDetect = useMobileDetect();

  const isInViewport = (element) => {
    const rect = element.getBoundingClientRect();
    return (
      rect.top >= 0 &&
      rect.left >= 0 &&
      rect.bottom <=
        (window.innerHeight || document.documentElement.clientHeight) &&
      rect.right <= (window.innerWidth || document.documentElement.clientWidth)
    );
  };

  useEffect(() => {
    const handleScroll = (e) => {
      e.stopPropagation();
      e.preventDefault();
      setLastScrollY(e.target.scrollTop / height);
      const element = document.getElementById('home-hero-1');
      const hero = document.getElementById('heroes-homepage');
      if (isInViewport(element)) {
        hero.style.overflowY = 'hidden';
      } else {
        hero.style.overflowY = 'scroll';
      }
    };
    const resetScrollParallax = () => {
      const element = document.getElementById('home-hero-1');
      const hero = document.getElementById('heroes-homepage');
      if (
        window.scrollY <= 0 &&
        hero?.style.overflowY === 'hidden' &&
        isInViewport(element)
      ) {
        hero.style.overflowY = 'scroll';
      }
    };
    lozadObserver.observe();
    document
      .getElementById('heroes-homepage')
      .addEventListener('scroll', handleScroll);
    document
      .getElementById('heroes-homepage')
      .addEventListener('onwheel', handleScroll);
    document.addEventListener('scroll', () => {
      resetScrollParallax();
    });
    document
      .getElementById('heroes-homepage')
      .addEventListener('mousemove', () => {
        resetScrollParallax();
      });

    const fin = document.getElementById('51');
    const rec = document.getElementById('55');
    const mar = document.getElementById('59');
    const des = document.getElementById('63');
    const dev = document.getElementById('23');
    const all =
      width > 1050
        ? [dev, mar, rec, fin, des, rec, dev, des]
        : [des, dev, mar, des, rec, fin, mar];
    setPoints(
      all
        .map(
          (ser) =>
            `${ser.offsetLeft + ser.offsetWidth / 2},${
              ser.offsetTop + ser.offsetHeight / 2
            }`
        )
        .join(' ')
    );

    if (lastScrollY > 2) {
      document.body.style['scroll-snap-type'] = 'unset';
    } else {
      document.body.style['overflow-y'] = 'auto';
      document.body.style['scroll-snap-type'] = 'y mandatory';
    }
    return () => {
      document.removeEventListener('scroll', handleScroll);
      document.removeEventListener('onwheel', handleScroll);
    };
  }, [width, height, lastScrollY]);
  const { homepage, referrals, services } = homePageData;
  const logos = mobileDetect.isMobile
    ? homepage.logos.slice(0, 10)
    : homepage.logos;
  return (
    <>
      <Head>
        <title key="title">{homepage.seoTitle}</title>
        <meta property="og:title" content={homepage.seoTitle} />
        <meta name="description" content={homepage.seoDescription} />
        <meta property="og:description" content={homepage.seoDescription} />
        <meta property="og:image" content={homepage.seoImage[0].url} />
      </Head>
      <Header
        hide={!inView}
        commonData={commonData}
        serviceLinksData={serviceLinksData}
        localized={homepage.localized}
      />
      <main id="home">
        <section ref={ref} id="heroes-homepage" className="heroes parallax">
          <div className="parallax-moving">
            <BGShapes />
          </div>
          {homepage.heroes &&
          homepage.heroes.every((hero) => hero.showServices) ? (
            <div className="teams-block">
              <div
                ref={ref}
                className={`teams-wrapper ${inView ? 'activated' : ''}`}
              >
                <svg
                  height={'100%'}
                  width={'100%'}
                  fillRule={'evenodd'}
                  opacity={lastScrollY}
                >
                  <polyline
                    id="ants"
                    fill="none"
                    className="path"
                    stroke="#A0B7D4"
                    points={points}
                    strokeWidth={3}
                    strokeDasharray={'0, 10'}
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
                {services.map((service) => (
                  <TeamBlock
                    key={service.id}
                    id={service.id}
                    name={service.title}
                    img={service.teamHero[0].logo[0]}
                    url={`/${router.locale}/${service.uri}`}
                  />
                ))}
              </div>
            </div>
          ) : (
            ''
          )}
          {homepage.heroes.map((hero, idx) => (
            <Hero
              key={hero.id}
              id={'home-hero-' + idx}
              idx={idx}
              className={`hero-${idx}`}
              hero={hero}
              services={services}
            />
          ))}
        </section>
        <section id="advantages">
          <div className="grid">
            {homepage.advantagesBlock.map((advantagesBlock) => (
              <SectionHeader
                key={advantagesBlock.id}
                title={advantagesBlock.advantagesTitle}
                subtitle={advantagesBlock.advantagesSubtitle}
              />
            ))}
            {homepage.advantagesContent.map((advantagesContent) => (
              <Advantage
                key={advantagesContent.id}
                title={advantagesContent.advantageContentTitle}
                description={advantagesContent.advantageContentDescription}
                img={advantagesContent.advantageLogo[0]}
              />
            ))}
          </div>
        </section>
        {homepage.extraNews &&
          homepage.extraNews.map(
            ({ id, header, subheader, image, cta, description }) => (
              <ExtraNews
                key={id}
                id="extra-news"
                title={header}
                subtitle={subheader}
                cta={cta}
                extraService={image}
                description={description}
              />
            )
          )}
        {homepage?.teaser?.map(
            ({
              id,
              teaserTitle,
              teaserSubtitle,
              teaserDescription,
              callToAction,
              teaserImage,
              customCtaUrl
            }) => (
              <Teaser
                key={id}
                teaserTitle={teaserTitle}
                teaserDescription={teaserDescription}
                teaserSubtitle={teaserSubtitle}
                callToAction={callToAction}
                teaserImage={teaserImage}
                customCtaUrl={customCtaUrl}
              />
            )
          )}
        <section id="audience">
          <div className="grid">
            {homepage.audienceBlock.map((audienceBlock) => (
              <div key={audienceBlock.id} className={'audience-header'}>
                <SectionHeader
                  key={audienceBlock.id}
                  title={audienceBlock.audienceTitle}
                  subtitle={audienceBlock.audienceSubtitle}
                />
                <RedactorContent
                  html={audienceBlock.audienceDescription}
                  className={'audience-description'}
                />
              </div>
            ))}
            {homepage.audienceContent.map((audienceContent) => (
              <Advantage
                key={audienceContent.id}
                title={audienceContent.audienceContentTitle}
                description={audienceContent.audienceContentDescription}
                img={audienceContent.audienceLogo[0]}
              />
            ))}
          </div>
        </section>
        <section className="projects">
          <div className="projects-container grid">
            {homepage.casesBlock.map((casesBlock) => (
              <Fragment key={casesBlock.id}>
                <SectionHeader
                  title={casesBlock.casesTitle}
                  subtitle={casesBlock.casesSubtitle}
                />
                {casesBlock.selectedCases.map((theCase) => (
                  <Project
                    key={theCase.id}
                    tags={theCase.caseServices}
                    title={theCase.title}
                    description={theCase.caseIntro}
                    imageSrc={theCase.caseHero[0].url}
                    uri={`/${router.locale}/cases/${theCase.slug}`}
                    ctaTitle={theCase.caseThumbCta[0].title}
                    ctaType={ConvertStringToCtaType(
                      theCase.caseThumbCta[0].ctaType
                    )}
                  />
                ))}
              </Fragment>
            ))}
          </div>
          <CTABanner {...homepage.ctaBanner[0]} />
        </section>
        <section id="references">
          <div className="grid">
            {homepage.referralsBlock.map((referralsBlock) => (
              <SectionHeader
                key={referralsBlock.id}
                title={referralsBlock.referralTitle}
                subtitle={referralsBlock.referralSubtitle}
              />
            ))}

            <Carousel
              className="carousel-wrapper"
              centerSlidePercentage={mobileDetect.isMobile ? 100 : 50}
              interval={5000}
              transitionTime={1000}
              useKeyboardArrows={true}
              emulateTouch={true}
              centerMode={true}
              infiniteLoop={false}
              showArrows={true}
              autoPlay={false}
              showThumbs={false}
              showStatus={false}
            >
              {referrals.map((referral) => (
                <Reference
                  key={referral.id}
                  client={referral.referralCustomer}
                  img={referral.referralImage[0]}
                  title={referral.title}
                  description={referral.referralDescription}
                />
              ))}
            </Carousel>
            <div id="logos">
              {logos.map(({ image: [{ width, height, url }], website, id }) => (
                <a
                  key={id}
                  target="_blank"
                  href={website}
                  rel="noreferrer noopener"
                >
                  <Image
                    alt="Logo"
                    data-src={url}
                    height={height}
                    width={width}
                  />
                </a>
              ))}
            </div>
          </div>
        </section>
        <section id="blog">
          <SectionHeader
            title={homepage.bloglist[0].header}
            subtitle={homepage.bloglist[0].subheader}
          />
          <BlogList limit={3} blogsData={blogsData} hideFilters hideSocials />
        </section>
      </main>
      <NewsLetter commonData={commonData} newsLetterData={newsLetterData} />
      <Footer
        serviceLinksData={serviceLinksData}
        commonData={commonData}
        cookieBannerData={cookieBannerData}
        localized={homepage.localized}
      />
    </>
  );
};

export default Index;
