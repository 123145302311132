import {Image} from "./Image";
import React from "react";
// import '../styling/advantage.scss';
import {RedactorContent} from "./redactor-content";

export type AdvantageProps = {
  title: string;
  description: string;
  img: { url: string; width: string | number; height: string | number; };
}
export const Advantage = (props: AdvantageProps) => <div className="advantage">
  <Image
    alt={props.title}
    {...props.img}
  />
  <h2>{props.title}</h2>
  <RedactorContent html={props.description}/>
</div>;
